@import url(./tailwind.css);
@import url(../fonts/adelbrook/Adelbrook.css);
@import url(../fonts/noto/NotoSans.css);

.dgn-item,
.ProseMirror {
    font-family: "NotoSans";

    &,
    p {
        font-size: 12pt;
        line-height: 1;
    }
}

.prose ul {
    list-style-image: url(./checkmark.svg);
    margin-bottom: 0.65em;

    li {
        padding-left: 0;
    }
}

.cds--col-md-2 .cds--btn-set .cds--btn {
    width: 50%;
}

.cds--col-md-2 .cds--date-picker--range .cds--date-picker-container,
.cds--col-md-2 .cds--date-picker--range .cds--date-picker__input {
    width: 100%;
}
