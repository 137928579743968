@font-face {
    font-family: "Adelbrook";
    font-weight: 700;
    src: url("./Adelbrook-Bold.woff2") format("woff2"),
        url("./Adelbrook-Bold.woff") format("woff");
}

@font-face {
    font-family: "Adelbrook";
    font-weight: 700;
    font-style: italic;
    src: url("./Adelbrook-BoldItalic.woff2") format("woff2"),
        url("./Adelbrook-BoldItalic.woff") format("woff");
}

@font-face {
    font-family: "Adelbrook";
    font-weight: 400;
    src: url("./Adelbrook-Regular.woff2") format("woff2"),
        url("./Adelbrook-Regular.woff") format("woff");
}

@font-face {
    font-family: "Adelbrook";
    font-weight: 400;
    font-style: italic;
    src: url("./Adelbrook-Italic.woff2") format("woff2"),
        url("./Adelbrook-Italic.woff") format("woff");
}

@font-face {
    font-family: "Adelbrook";
    font-weight: 500;
    src: url("./Adelbrook-Medium.woff2") format("woff2"),
        url("./Adelbrook-Medium.woff") format("woff");
}

@font-face {
    font-family: "Adelbrook";
    font-weight: 500;
    font-style: italic;
    src: url("./Adelbrook-MediumItalic.woff2") format("woff2"),
        url("./Adelbrook-MediumItalic.woff") format("woff");
}
