@font-face {
    font-family: "NotoSans";
    font-weight: 700;
    src: url("./NotoSans-Bold.woff2") format("woff2");
}

@font-face {
    font-family: "NotoSans";
    font-weight: 700;
    font-style: italic;
    src: url("./NotoSans-BoldItalic.woff2") format("woff2");
}

@font-face {
    font-family: "NotoSans";
    font-weight: 400;
    src: url("./NotoSans-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "NotoSans";
    font-weight: 400;
    font-style: italic;
    src: url("./NotoSans-Italic.woff2") format("woff2");
}

@font-face {
    font-family: "NotoSans";
    font-weight: 500;
    src: url("./NotoSans-Medium.woff2") format("woff2");
}

@font-face {
    font-family: "NotoSans";
    font-weight: 500;
    font-style: italic;
    src: url("./NotoSans-MediumItalic.woff2") format("woff2");
}
