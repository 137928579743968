@use "@carbon/styles" with (
    $css--reset: false,
    $use-flexbox-grid: true
);
@use "@carbon/themes/scss/themes";
@import "@carbon/themes";

:root {
    @include theme(themes.$g10);
}

body {
    background: var(--cds-background);
}

.cds--btn.cds--btn--icon-only.cds--header__action svg {
    color: var(--cds-icon-secondary, #525252);
}
