@import url(prosemirror-menu/style/menu.css);

.ut-rich-text {
    div.ProseMirror {
        outline: 0;
        padding: 4px;

        img {
            display: inline-block;
            margin: 0;
        }
    }
}
